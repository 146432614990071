import { Button } from '@/components/Button';
import { Link } from '@/components/Link';
import AppStoreBanner from '@/hant/public/image/app-store-banner.svg';
import GooglePlayBanner from '@/hant/public/image/google-play-banner.svg';
import HantLogo from '@/hant/public/image/hant-logo.svg';
import { StandaloneFooter, StandaloneFooterProps } from 'base/components/Footer';
import { Footer } from 'base/components/Footer/Footer';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { useMobileAppState } from 'lib/hooks/useMobileAppState';

const currentYear = new Date().getFullYear();

const copyright: StandaloneFooterProps['copyright'] = (
  <span>
    För prenumerationsärenden, ring 042 444 30 00. Ansvarig utgivare Tobias Wixtröm. © Copyright {currentYear}{' '}
    <a className="underline" href="https://www.hant.se">
      Hänt.se
    </a>
    <br />
    Hänt är en del av{' '}
    <a className="underline" target="_blank" href="https://www.aller.se">
      Aller Media
    </a>
    . Humlegårdsgatan 6, 114 46 Stockholm, 08 679 46 00.
  </span>
);

const description: StandaloneFooterProps['description'] = (
  <>
    <a href="https://www.hant.se">Hänt</a> är Sveriges mötesplats och nyhetskälla för dig som älskar{' '}
    <a href="https://www.hant.se/noje">kändis och nöjesvärlden</a>.
  </>
);

const editors: StandaloneFooterProps['editors'] = `Chefredaktör och ansvarig utgivare: Tobias Wixtröm`;

const logo: StandaloneFooterProps['logo'] = <HantLogo width={100} height={31} />;

const soMe: StandaloneFooterProps['soMe'] = (
  <Footer.SoMe.Group>
    <Footer.SoMe.Headline>Följ oss</Footer.SoMe.Headline>

    <Footer.SoMe href="https://www.facebook.com/hant.aller" name="facebook" aria-label="Länk till Facebook" />
    <Footer.SoMe href="https://www.instagram.com/hant.se" name="instagram" aria-label="Länk till Instagram" />
    <Footer.SoMe href="https://www.tiktok.com/@hant.se" name="tiktok" aria-label="Länk till Tiktok" />
    <Footer.SoMe
      href="https://www.youtube.com/channel/UCvFNOSgJoMb5xTj02Y5NZ8g"
      name="youtube"
      aria-label="Länk till Youtube"
    />
    <Button
      link={{
        href: 'https://news.google.com/publications/CAAqBwgKMPT9lQswx6GtAw?hl=sv&gl=SE&ceid=SE%3Asv',
        target: '_blank',
      }}
      options={{
        size: 'small',
        className: 'bg-gray-700 border-none',
        'aria-label': 'Länk till Google nyheter',
      }}
      content={
        <>
          <GoogleNewsIcon className="w-6" />
          <span className="whitespace-nowrap">Google nyheter</span>
        </>
      }
    />
  </Footer.SoMe.Group>
);

export const HantStandaloneFooter: typeof StandaloneFooter = () => {
  const { isMobileApp } = useMobileAppState();

  const columns: StandaloneFooterProps['columns'] = (
    <>
      <Footer.Column>
        <Footer.List.Title>Om Hänt.se</Footer.List.Title>
        <Footer.List>
          <Footer.List.Item>
            <Link href="/om-oss" content="Om oss" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/nyhetsbrev" content="Nyhetsbrev" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/alla-amnen" content="Alla ämnen" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/vara-skribenter" content="Våra skribenter" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="/redaktionell-policy" content="Redaktionell policy" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="https://www.aller.se/integritetspolicy/" target="_blank" content="Integritetspolicy" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link href="https://www.aller.se/ai-policy/" target="_blank" content="Aller Medias AI-policy" />
          </Footer.List.Item>
          <Footer.List.Item>
            <Link
              href="/creative-studio/riktlinjer-for-kommersiellt-innehall-pa-hantse/10293476"
              content="Kommersiell policy Hänt"
            />
          </Footer.List.Item>
        </Footer.List>
      </Footer.Column>

      <Footer.Column>
        <Footer.List.Title>Kontakta oss</Footer.List.Title>
        <Footer.List>
          <Footer.List.Item>
            <Link href="/kontakta-oss" content="Kontakta oss" />
          </Footer.List.Item>

          <Footer.List.Item>
            <Link href="/tipsa-oss" content="Tipsa oss" />
          </Footer.List.Item>

          <Footer.List.Item>
            <Link href="/lasarpanelen" content="Läsarpanel" />
          </Footer.List.Item>

          <Footer.List.Item>
            <Link href="https://hant.ocast.com" target="_blank" content="Annonsera hos oss" />
          </Footer.List.Item>

          <Footer.List.Item>
            <Link href="https://prenumerera.se/tidningar/hant-extra" target="_blank" content="Prenumerera" />
          </Footer.List.Item>

          <Footer.List.Item>
            <Link href="https://prenumerera.se/tidningar/hant-extra" target="_blank" content="Hänt Extra" />
          </Footer.List.Item>

          <Footer.List.Item>
            <Link href="https://prenumerera.se/tidningar/hant-i-veckan" target="_blank" content="Hänt i veckan" />
          </Footer.List.Item>
        </Footer.List>
      </Footer.Column>

      {!isMobileApp && (
        <Footer.Column className="hide-in-mobile-app">
          <Footer.List.Title>Hänt appen</Footer.List.Title>

          <Footer.List>
            <Footer.List.Item>
              <Link
                href="https://apps.apple.com/se/app/h%C3%A4nt/id1247376163"
                target="_blank"
                content={<AppStoreBanner />}
                aria-label="Länk till App store"
              />
            </Footer.List.Item>

            <Footer.List.Item>
              <Link
                href="https://play.google.com/store/apps/details?id=se.aller.hant"
                target="_blank"
                content={<GooglePlayBanner />}
                aria-label="Länk till Google play"
              />
            </Footer.List.Item>
          </Footer.List>
        </Footer.Column>
      )}
    </>
  );

  return <StandaloneFooter {...{ columns, copyright, description, editors, logo, soMe }} />;
};
