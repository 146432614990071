/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "accordion": {
    "extend": {
      "toggle_variant_default": "sm:hidden",
      "content_variant_default": "sm:block"
    }
  },
  "link": {
    "colors_bar": "text-white",
    "colors_drawer": "text-black",
    "size_bar": "p-2.5",
    "size_controls": "p-0",
    "size_drawer": "py-2",
    "variant_bar": "text-headline-xs inline-block",
    "variant_drawerPrimary": "text-headline-sm block",
    "variant_drawerSecondary": "text-headline-sm !font-normal block py-3 sm:py-2 last:pb-6 sm:last:pb-2"
  },
  "bar_group_colors_default": "bg-primary-700",
  "bar_group_size_default": "-mx-4",
  "bar_group_variant_default": "relative overflow-x-auto overflow-y-hidden row-start-2 md:row-auto col-span-3 md:col-auto md:mx-0 md:group-data-[open]:max-w-screen-sm hidden md:block",
  "bar_colors_default": "bg-primary-700 wings-primary-700",
  "bar_size_default": "px-4 md:px-6 py-3 gap-3",
  "bar_variant_default": "grid grid-cols-[auto_auto_1fr] md:grid-cols-[auto_auto_1fr_auto] items-center relative wings",
  "button_colors_primary": "",
  "button_colors_secondary": "",
  "button_size_small": "border-0",
  "button_size_medium": "",
  "button_size_large": "",
  "button_variant_default": "whitespace-nowrap",
  "drawer_toggle_animation_default": "",
  "drawer_toggle_animation_alternative": "",
  "drawer_toggle_colors_default": "",
  "drawer_toggle_size_default": "",
  "drawer_toggle_variant_default": "",
  "drawer_colors_default": "bg-white wings-white",
  "drawer_size_default": "px-3 py-5 w-full h-[calc(100dvh_-_100%)] gap-y-5 sm:px-8 sm:py-8 md:px-16 md:py-10 lg:px-25 lg:py-13",
  "drawer_variant_default": "-translate-y-[120%] -z-2 absolute flex-col flex group-data-[open]:translate-y-0 left-0 overflow-y-auto overflow-x-hidden top-full transition-transform wings lg:overflow-visible",
  "logo_colors_default": "text-primary-900",
  "logo_colors_alternative": "",
  "logo_size_default": "md:mx-2 w-[90px] h-[27px] md:w-[120px] md:h-[46px]",
  "logo_size_alternative": "",
  "logo_variant_default": "transition-all relative md:top-0.75",
  "menu_group_colors_default": "",
  "menu_group_size_default": "sm:gap-2 md:gap-3 lg:gap-4",
  "menu_group_variant_default": "sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 lg:grid-rows-[min-content_auto] lg:overflow-y-auto transition-opacity opacity-0 pointer-events-none group-data-[open]:opacity-100 group-data-[open]:pointer-events-auto grow",
  "menu_colors_primary": "",
  "menu_colors_secondary": "",
  "menu_colors_none": "",
  "menu_size_bar": "h-12 gap-x-3 px-1.5 lg:px-3",
  "menu_size_controls": "gap-x-3",
  "menu_size_drawer": "gap-0",
  "menu_variant_bar": "flex group-data-[open]:hidden flex-row items-center overflow-x-auto overflow-y-hidden md:justify-end",
  "menu_variant_controls": "flex flex-row items-center justify-self-end",
  "menu_variant_drawer": "flex flex-col sm:overflow-x-hidden",
  "search_toggle_colors_default": "bg-primary-800 sm:bg-transparent text-white sm:text-primary-700",
  "search_toggle_size_default": "",
  "search_toggle_variant_default": "cursor-pointer relative",
  "search_colors_default": "bg-transparent",
  "search_size_bar": "w-full h-12 gap-x-2",
  "search_size_drawer": "w-full h-12 gap-x-2 max-w-screen-sm mx-auto",
  "search_variant_bar": "hidden lg:group-data-[open]:flex flex-row items-center",
  "search_variant_drawer": "[&_button]:hidden flex flex-row items-center sm:[&_button]:block lg:hidden",
  "soMe_group_colors_default": "",
  "soMe_group_size_default": "gap-3 py-3 lg:pt-8 sm:ml-auto lg:ml-0",
  "soMe_group_variant_default": "row-start-3 lg:row-start-2 md:col-start-2 lg:col-start-1 sm:col-span-2 self-end lg:self-start flex flex-col",
  "soMe_headline_colors_default": "",
  "soMe_headline_size_default": "text-preamble-sm w-full",
  "soMe_headline_variant_default": "",
  "soMe_colors_default": "",
  "soMe_size_default": "gap-3",
  "soMe_variant_default": "self-end flex flex-wrap",
  "textField_input_colors_default": "",
  "textField_input_size_default": "!w-full",
  "textField_input_size_large": "!w-full",
  "textField_input_variant_default": "",
  "textField_colors_default": "",
  "textField_size_default": "",
  "textField_variant_default": "flex-grow",
  "colors_default": "",
  "size_default": "w-full max-w-screen-xl mx-auto",
  "variant_default": "sticky top-0 left-0 z-menu group"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "Navigation");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;