import { Button } from '@/components/Button';
import { Icon } from '@/components/Icon';
import HantLogo from '@/hant/public/image/hant-logo.svg';
import MenuIconApp from '@/hant/public/image/menu-icon-app.svg';
import MenuIconNewsletter from '@/hant/public/image/menu-icon-newsletter.svg';
import MenuIconTips from '@/hant/public/image/menu-icon-tips.svg';
import { useMobileAppState } from '@/hooks/useMobileAppState';
import { StandaloneNavigation, StandaloneNavigationProps } from 'base/components/Navigation';
import { Navigation } from 'base/components/Navigation/Navigation';
import { getNavigationDrawerMenu } from 'base/components/Navigation/utils/getNavigationDrawerMenu';
import GoogleNewsIcon from 'base/public/image/google-news.svg';
import { cn } from 'lib/utils';
import { showOneTrustConsent } from 'lib/utils/oneTrust';
import { ReactNode } from 'react';

const menu: StandaloneNavigationProps['menu'] = (
  <>
    <Navigation.Link href="/noje" content="Nöje" />
    <Navigation.Link href="/kungligt" content="Kungligt" />
    <Navigation.Link href="/video/" content="Hänt TV" />
    <Navigation.Link href="/hollywood" content="Hollywood" />
  </>
);

const controls: StandaloneNavigationProps['controls'] = (
  <button type="button" title="Cookies" onClick={showOneTrustConsent}>
    <Icon name="cookie" options={{ size: 'medium', className: 'text-white' }} />
  </button>
);

const logo: StandaloneNavigationProps['logo'] = <HantLogo />;

const getStyledLink = ({
  href,
  icon,
  title,
  description,
}: {
  href: string;
  icon: ReactNode;
  title: string;
  description: string;
}) => ({
  href,
  content: (
    <>
      <div className="flex h-15 w-15 shrink-0 items-center justify-center rounded-full bg-white sm:bg-gray-100">
        {icon}
      </div>
      <div>
        <h4 className="text-headline-sm group-hover/link:underline">{title}</h4>
        <p className="text-body-xs pt-1.5">{description}</p>
      </div>
    </>
  ),
  options: {
    colors: 'none',
    size: 'none',
    variant: 'none',
    className: 'group/link flex flex-row gap-3 mb-3 last:mb-0',
    'data-activity-item': title,
  },
});

const getDrawer = () =>
  getNavigationDrawerMenu({
    menu: [
      {
        links: [
          { href: '/noje', content: 'Nöje' },
          { href: '/kungligt', content: 'Kungligt' },
          { href: '/hollywood', content: 'Hollywood' },
        ],
      },
      {
        links: [
          { href: '/etikett/h%C3%A4nt%20exklusivt', content: 'Exklusivt på Hänt' },
          { href: '/korsord', content: 'NYTT! Spel' },
          { href: '/etikett/serier och tv-program', content: 'Serier och TV-program' },
          { href: '/etikett/bakom%20kulisserna', content: 'Bakom kulisserna' },
          { href: '/livshistorier', content: 'Livshistorier' },
          { href: '/video/', content: 'Hänt TV' },
          { href: '/idol', content: 'Idol' },
        ],
      },
      {
        links: [
          { href: '/om-oss', content: 'Om oss', options: { variant: 'drawerSecondary' } },
          { href: '/kontakta-oss', content: 'Kontakta oss', options: { variant: 'drawerSecondary' } },
          { href: '/om-cookies', content: 'Om cookies', options: { variant: 'drawerSecondary' } },
          {
            href: '#',
            content: 'Hantera preferenser',
            options: {
              variant: 'drawerSecondary',
              'aria-label': 'Hantera preferenser',
              onClick: (e) => {
                showOneTrustConsent();
                e.preventDefault();
              },
            },
          },
          { href: '/alla-amnen', content: 'Alla ämnen', options: { variant: 'drawerSecondary' } },
          { href: '/vara-skribenter', content: 'Våra skribenter', options: { variant: 'drawerSecondary' } },
          { href: '/creative-studio', content: 'Creative studio', options: { variant: 'drawerSecondary' } },
        ],
      },
      {
        links: [
          getStyledLink({
            href: '/nyhetsbrev',
            icon: <MenuIconNewsletter className="w-5" />,
            title: 'Få de senaste nyheterna',
            description: 'Signa upp dig för Hänts nyhetsbrev!',
          }),
          getStyledLink({
            href: '/hant-app',
            icon: <MenuIconApp className="h-5.5" />,
            title: 'Ladda hem appen',
            description: 'Få notiser precis när det händer!',
          }),
          getStyledLink({
            href: '/tipsa-oss',
            icon: <MenuIconTips className="w-4.5" />,
            title: 'Tipsa oss',
            description: 'Vid publiceringar kan tipspengar utgå!',
          }),
        ],
      },
    ],
  });

const soMe: StandaloneNavigationProps['soMe'] = (
  <Navigation.SoMe.Group className="cts-impression-group">
    <Navigation.SoMe.Headline>Följ Hänt</Navigation.SoMe.Headline>
    <div className="flex flex-wrap gap-3">
      <Navigation.SoMe
        href="https://sv-se.facebook.com/hant.aller/"
        name="facebook"
        data-some-icon="facebook"
        options={{ className: 'cts-impression-item' }}
        aria-label="Länk till Facebook"
      />
      <Navigation.SoMe
        href="https://www.instagram.com/hant.se/"
        name="instagram"
        data-some-icon="instagram"
        options={{ className: 'cts-impression-item' }}
        aria-label="Länk till Instagram"
      />
      <Navigation.SoMe
        href="https://www.tiktok.com/@hant.se"
        name="tiktok"
        data-some-icon="tiktok"
        options={{ className: 'cts-impression-item' }}
        aria-label="Länk till Tiktok"
      />
      <Navigation.SoMe
        href="https://www.youtube.com/channel/UCvFNOSgJoMb5xTj02Y5NZ8g"
        name="youtube"
        data-some-icon="youtube"
        options={{ className: 'cts-impression-item' }}
        aria-label="Länk till Youtube"
      />

      <Button
        link={{
          href: 'https://news.google.com/publications/CAAqBwgKMPT9lQswx6GtAw?hl=sv&gl=SE&ceid=SE%3Asv',
          target: '_blank',
        }}
        options={{
          className: cn('cts-impression-item', 'bg-gray-700'),
          size: 'small',
          'aria-label': 'Länk till Google nyheter',
        }}
        content={
          <>
            <GoogleNewsIcon className="w-6" />
            <span className="whitespace-nowrap">Google nyheter</span>
          </>
        }
      />
    </div>
  </Navigation.SoMe.Group>
);

export const HantStandaloneNavigation: typeof StandaloneNavigation = () => {
  const { isMobileApp } = useMobileAppState();

  if (isMobileApp) {
    return null;
  }

  const drawer = getDrawer();

  return (
    <StandaloneNavigation
      options={{
        className: 'hide-in-mobile-app',
      }}
      {...{ menu, controls, logo, drawer, soMe }}
    />
  );
};
