/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "colors_plain": "ring-secondary-200 border-none bg-none text-gray-700 hover:bg-gray-600 hover:text-white focus:bg-gray-800 focus:text-white active:bg-gray-800 active:text-white",
  "colors_filled": "ring-secondary-200 border-none bg-gray-700 text-white hover:bg-gray-600 hover:text-white focus:bg-gray-800 focus:text-white active:bg-gray-800 active:text-white",
  "colors_border": "ring-secondary-200 gray-700 bg-none text-gray-700 hover:bg-gray-600 hover:text-white focus:bg-gray-800 focus:text-white active:bg-gray-800 active:text-white",
  "size_default": "w-8 h-8",
  "variant_default": "inline-flex items-center justify-center transition-all duration-200 ease-in-out ring-0 border rounded-full focus:ring-4 hover:ring-0 active:ring-0"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "SoMe");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;